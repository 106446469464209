











































































































@import '@design';

.img-elem {
  border: solid 6px white;

  @media screen and (min-width: 600px) {
    border: solid 10px $color-bluegiant-blue;
  }
}
